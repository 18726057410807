import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button, Text } from '@radix-ui/themes';
import { useTranslation } from 'react-i18next';
import { BaseContextMenu, type ContextMenuRef } from './BaseContextMenu';

interface ZoomContextMenuProps {
  onClose?: () => void;
  onConfirmZoom: () => void;
  onAbortZoom: () => void;
  zoomRangeLabel: string;
  refAreaLeft?: string | null;
  refAreaRight?: string | null;
}

export interface ZoomContextMenuRef {
  open: (position: { x: number; y: number }) => void;
  close: () => void;
}

export const ZoomContextMenu = forwardRef<
  ZoomContextMenuRef,
  ZoomContextMenuProps
>(
  (
    {
      onClose,
      onConfirmZoom,
      onAbortZoom,
      zoomRangeLabel,
      refAreaLeft,
      refAreaRight,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const baseMenuRef = useRef<ContextMenuRef>(null);

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          onConfirmZoom();
        } else if (event.key === 'Escape') {
          event.preventDefault();
          onAbortZoom();
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => document.removeEventListener('keydown', handleKeyDown);
    }, [onConfirmZoom, onAbortZoom]);

    const isValidSelection = !!(
      refAreaLeft &&
      refAreaRight &&
      Math.abs(parseFloat(refAreaRight) - parseFloat(refAreaLeft)) > 0
    );

    const isWithinChartArea = (pos: { x: number; y: number }) => {
      return Array.from(document.querySelectorAll('.recharts-area')).some(
        (el) => {
          const areaBounds = el.getBoundingClientRect();
          return (
            pos.x >= areaBounds.left &&
            pos.x <= areaBounds.right &&
            pos.y >= areaBounds.top &&
            pos.y <= areaBounds.bottom
          );
        }
      );
    };

    useImperativeHandle(ref, () => ({
      open: (clickPosition: { x: number; y: number }) => {
        if (isValidSelection && isWithinChartArea(clickPosition)) {
          setPosition({
            x: clickPosition.x,
            y: clickPosition.y - 60,
          });
          baseMenuRef.current?.open();
        }
      },
      close: () => {
        baseMenuRef.current?.close();
      },
    }));

    return (
      <div
        style={{
          position: 'fixed',
          left: position.x,
          top: position.y,
        }}
      >
        <BaseContextMenu
          ref={baseMenuRef}
          align="center"
          onClose={onClose}
          size="2"
        >
          <Text size="2">{zoomRangeLabel}</Text>
          <Button
            size="2"
            variant="ghost"
            color="gray"
            onClick={() => {
              onConfirmZoom();
              onClose?.();
            }}
            style={{
              width: 'auto',
              justifyContent: 'space-between',
            }}
          >
            <Text>{t('charts.confirmZoom')}</Text>
            <Text style={{ opacity: 0.5 }}>⏎</Text>
          </Button>
          <Button
            size="2"
            variant="ghost"
            color="gray"
            onClick={() => {
              onAbortZoom();
              onClose?.();
            }}
            style={{
              width: 'auto',
              justifyContent: 'space-between',
            }}
          >
            <Text>{t('charts.abortZoom')}</Text>
            <Text style={{ opacity: 0.5 }}>⎋</Text>
          </Button>
        </BaseContextMenu>
      </div>
    );
  }
);

ZoomContextMenu.displayName = 'ZoomContextMenu';
