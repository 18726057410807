import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Popover, Flex, FlexProps } from '@radix-ui/themes';
import { PopoverAnchor, PopoverContentProps } from '@radix-ui/react-popover';
import { PopoverContentOwnProps } from '@radix-ui/themes/dist/cjs/components/popover.props';

export interface ContextMenuRef {
  open: () => void;
  close: () => void;
}

interface BaseContextMenuProps {
  children: React.ReactNode[] | React.ReactNode;
  onClose?: () => void;
  align?: PopoverContentProps['align'];
  size?: PopoverContentOwnProps['size'];
  gap?: FlexProps['gap'];
}

export const BaseContextMenu = forwardRef<ContextMenuRef, BaseContextMenuProps>(
  ({ children, onClose, align, size = '2', gap = '2' }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpen(true);
      },
      close: () => {
        setIsOpen(false);
        onClose?.();
      },
    }));

    if (!isOpen) return null;

    return (
      <Popover.Root open={isOpen} onOpenChange={(open) => !open && onClose?.()}>
        <PopoverAnchor />
        <Popover.Content
          size={size}
          collisionPadding={10}
          align={align}
          sideOffset={5}
          onInteractOutside={() => {
            setIsOpen(false);
            onClose?.();
          }}
        >
          <Flex direction="column" gap={gap} style={{ width: '100%' }}>
            {children}
          </Flex>
        </Popover.Content>
      </Popover.Root>
    );
  }
);

BaseContextMenu.displayName = 'BaseContextMenu';
