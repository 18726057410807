import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInDays, differenceInMinutes } from 'date-fns';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { ZoomContextMenu } from 'components/CustomContextMenu/ZoomContextMenu';

import Box from '@mui/material/Box';
import { Flex, Skeleton, Spinner, Text } from '@radix-ui/themes';

import { CustomXAxisTick } from 'components/CustomXAxisTick';
import { ChartCustomLabel } from 'components/ChartCustomLabel';
import { LiveIndicator } from 'components/LiveIndicator';

import { getIsIntervalMoreTwoWeeks } from 'utils/helpers';
import { MAX_Y_AXIS, MIN_Y_AXIS } from 'utils/constants';

import { FetchFilterProps, fetchMachineEvents } from 'services/API/machine';

import { machinesQueryKeys } from 'enums/MachinesQueryKeys.enum';

import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';
import { useGeneralChartData } from 'hooks/ChartData/useGeneralChartData';
import { useNoDataEvents } from 'hooks/ChartData/useNoDataEvent';

import { CustomerEvent } from 'types/event';

import { theme } from 'styles/theme';

import { keepPreviousDataOnRefetch } from 'utils/helpers/queryHelpers';
import { DateRanges } from 'enums/DateRanges.enum';
import { PermissionsGate } from 'components/PermissionsGate';
import { UserScopes } from 'enums/UserScopes.enum';
import { useFindMachineTree } from 'hooks/useMachineTree/useMachineTree';
import { useQueryWithError } from 'hooks/useQueryWithError';
import { useGetAggregatedHealthData } from 'hooks/Chart/useGetAggregatedHealthData';
import { useAbortQueryOnCleanup } from 'hooks/useAbortQueryOnCleanup';
import { AlertSubscriptionControl } from 'components/AlertSubscriptionControl';

import { useChartZoom } from 'hooks/Chart/useChartZoom';
import { ReferenceBand } from './ReferenceBand';
import { ChartTooltip } from '../ChartTooltip';
import { ChartCursor } from '../ChartCursor';

interface GeneralOverviewChartProps {
  options: FetchFilterProps;
  liveUpdate?: boolean;
  additionalSettings?: React.ReactNode;
  isSensorPage?: boolean;
}

export const filterEvents = (data?: CustomerEvent[]) => {
  const currTime = new Date();

  if (data) {
    return data.filter(
      (point) => differenceInMinutes(currTime, new Date(point.end)) < 60
    );
  }
  return [];
};

export const GeneralOverviewChart: FC<GeneralOverviewChartProps> = ({
  options,
  liveUpdate,
  additionalSettings,
  isSensorPage,
}) => {
  const { t } = useTranslation();

  const {
    dateRange,
    customEndDate,
    customStartDate,
    setCustomStartDateRange,
    setCustomEndDateRange,
  } = useChartCacheData();

  const hourView = dateRange === DateRanges.HOUR;

  const isLongDateFormat =
    !dateRange && getIsIntervalMoreTwoWeeks(customStartDate, customEndDate);

  const isRangeDateMoreThanOneDay = useCallback(() => {
    if (!dateRange && customEndDate && customStartDate) {
      return differenceInDays(customEndDate, customStartDate) > 1;
    }

    return false;
  }, [customEndDate, customStartDate, dateRange]);

  const { data: machineTree } = useFindMachineTree(options.id);

  const currentMachineTree = machineTree?.[0];
  const {
    data: healthData,
    isPending,
    isFetching,
  } = useGetAggregatedHealthData({
    options,
    machineTree: currentMachineTree,
  });

  const renderCustomTooltip = useCallback(
    ({ payload: payloads }: TooltipProps<string, string>) => {
      if (!payloads?.length) {
        return null;
      }

      const { date, showTooltip } = payloads[0].payload;

      return showTooltip ? (
        <ChartTooltip
          date={date}
          payloads={payloads}
          valueFormatter={(value) =>
            value === 'NaN' ? t('emptyMessage.default') : `${value || 0}%`
          }
          isRangeMoreOneDay={isRangeDateMoreThanOneDay()}
        />
      ) : null;
    },
    [isRangeDateMoreThanOneDay, t]
  );

  const { data: eventsData = [] } = useQueryWithError<CustomerEvent[]>({
    queryKey: machinesQueryKeys.filteredMachineEvents(options),
    queryFn: ({ signal }) => fetchMachineEvents(options, signal),
    retry: 0,
    placeholderData: (previousData, previousQuery) =>
      keepPreviousDataOnRefetch(previousData, previousQuery, options),
  });

  useAbortQueryOnCleanup(machinesQueryKeys.filteredMachineEvents(options));

  const noDataEvents = useNoDataEvents(healthData || []);
  const events = [...eventsData, ...noDataEvents];

  const generalChartData = useGeneralChartData(
    dateRange,
    healthData,
    liveUpdate && hourView ? filterEvents(events) : events
  );

  const {
    contextMenuRef,
    refAreaLeft,
    refAreaRight,
    selectionArea,
    handleZoomConfirm,
    handleZoomAbort,
    handleContextMenuClose,
    mouseDownHandler,
    mouseMoveHandler,
    mouseUpHandler,
    getZoomRangeLabel,
  } = useChartZoom({
    setCustomStartDateRange,
    setCustomEndDateRange,
  });

  return (
    <Box
      sx={{
        border: '1px solid',
        width: '100%',
        height: 'fit-content',
        borderRadius: '4px',
        marginBottom: '10px',
        borderColor: (theme) => theme.palette.custom.borderColor,
        backgroundColor: (theme) => theme.palette.custom.white,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          borderBottom: '1px solid',
          padding: '6px 16px',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '157%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderColor: (theme) => theme.palette.custom.borderColor,
        }}
      >
        <span style={{ minWidth: '90px' }}>
          {t('charts.generalOverviewTitle')}
        </span>
        {isFetching && !isPending && (
          <Flex align="baseline" gap="1">
            <Spinner size="1" />
            <Text>{t('updating')}...</Text>
          </Flex>
        )}
        <Flex gap="2">
          <PermissionsGate scopes={[UserScopes.AlertSubscriptions]}>
            <AlertSubscriptionControl machinePartId={options.id} />
          </PermissionsGate>
          {additionalSettings}
        </Flex>
      </Box>
      <Skeleton loading={isPending}>
        <ResponsiveContainer width="100%" height={320}>
          <ComposedChart
            syncId="syncId"
            data={generalChartData}
            margin={{
              top: 30,
              right: 40,
              left: isSensorPage ? 20 : 63,
              bottom: 30,
            }}
            onMouseDown={mouseDownHandler}
            onMouseMove={mouseMoveHandler}
            onMouseUp={mouseUpHandler}
          >
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              padding={{ left: isSensorPage ? 10 : 15 }}
              interval="preserveStart"
              tick={
                <CustomXAxisTick
                  isLongDateFormat={isLongDateFormat}
                  data={generalChartData}
                />
              }
              tickCount={24}
              type="number"
              scale="time"
              domain={['auto', 'auto']}
            />
            <YAxis
              dataKey="health"
              axisLine={false}
              tickLine={false}
              tickCount={5}
              tickMargin={isSensorPage ? 0 : 10}
              domain={[MIN_Y_AXIS, MAX_Y_AXIS]}
            />
            {events?.map((item) => {
              return (
                <Area
                  type="step"
                  dataKey={item.id}
                  fill={item.type.hexcolor}
                  fillOpacity={0.1}
                  strokeWidth={0}
                  activeDot={false}
                  key={item.id}
                  id={item.id}
                  label={
                    <ChartCustomLabel maxValue={MAX_Y_AXIS} event={item} />
                  }
                  isAnimationActive={false}
                />
              );
            })}
            <CartesianGrid opacity={0.3} />
            <ReferenceArea
              shape={<ReferenceBand isSensorPage={isSensorPage} />}
            />
            {((refAreaLeft && refAreaRight) ||
              (selectionArea.left && selectionArea.right)) && (
              <ReferenceArea
                x1={refAreaLeft || selectionArea.left || 0}
                x2={refAreaRight || selectionArea.right || 0}
                strokeOpacity={0.3}
                fill="#8884d8"
                fillOpacity={0.3}
              />
            )}

            <Line
              type="linear"
              dataKey="health"
              stroke={theme.lightBlue}
              fillOpacity={0}
              strokeWidth={2}
              activeDot={false}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="linear"
              dataKey="forecast"
              stroke={theme.lightBlue}
              fillOpacity={0}
              strokeWidth={2}
              activeDot={false}
              dot={false}
              strokeDasharray="5 5"
              isAnimationActive={false}
            />
            <Tooltip
              content={renderCustomTooltip}
              cursor={<ChartCursor />}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
        <ZoomContextMenu
          ref={contextMenuRef}
          onClose={handleContextMenuClose}
          onConfirmZoom={handleZoomConfirm}
          onAbortZoom={handleZoomAbort}
          zoomRangeLabel={getZoomRangeLabel()}
          refAreaLeft={refAreaLeft}
          refAreaRight={refAreaRight}
        />
      </Skeleton>
      <Flex position="absolute" right="48px" top="85px">
        <LiveIndicator isEnabled={!!liveUpdate && hourView && !isPending} />
      </Flex>
    </Box>
  );
};
