import { useRef, useState, useCallback } from 'react';
import { ZoomContextMenuRef } from 'components/CustomContextMenu/ZoomContextMenu';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { useTranslation } from 'react-i18next';
import { Languages } from 'enums/Languages.enum';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { format } from 'date-fns';

interface UseChartZoomProps {
  setCustomStartDateRange: (date: Date) => void;
  setCustomEndDateRange: (date: Date) => void;
}

export const useChartZoom = ({
  setCustomStartDateRange,
  setCustomEndDateRange,
}: UseChartZoomProps) => {
  const contextMenuRef = useRef<ZoomContextMenuRef>(null);
  const [refAreaLeft, setRefAreaLeft] = useState<string | null>(null);
  const [refAreaRight, setRefAreaRight] = useState<string | null>(null);
  const [pendingZoom, setPendingZoom] = useState<{
    start: Date | null;
    end: Date | null;
  }>({ start: null, end: null });
  const [selectionArea, setSelectionArea] = useState<{
    left: string | null;
    right: string | null;
  }>({ left: null, right: null });

  const openContextMenu = useCallback(
    (event: MouseEvent | React.MouseEvent) => {
      contextMenuRef.current?.open({
        x: event.clientX,
        y: event.clientY,
      });
    },
    []
  );

  const handleZoomConfirm = useCallback(() => {
    if (pendingZoom.start && pendingZoom.end) {
      setCustomStartDateRange(pendingZoom.start);
      setCustomEndDateRange(pendingZoom.end);
      setPendingZoom({ start: null, end: null });
      setSelectionArea({ left: null, right: null });
      contextMenuRef.current?.close();
    }
  }, [pendingZoom, setCustomStartDateRange, setCustomEndDateRange]);

  const handleZoomAbort = useCallback(() => {
    setPendingZoom({ start: null, end: null });
    setSelectionArea({ left: null, right: null });
    contextMenuRef.current?.close();
  }, []);

  const handleContextMenuClose = useCallback(() => {
    setPendingZoom({ start: null, end: null });
    setSelectionArea({ left: null, right: null });
    setRefAreaLeft(null);
    setRefAreaRight(null);
  }, []);

  const zoom = useCallback(
    (event: MouseEvent | React.MouseEvent) => {
      if (!refAreaLeft || !refAreaRight) {
        setRefAreaLeft(null);
        setRefAreaRight(null);
        return;
      }

      let [leftNum, rightNum] = [Number(refAreaLeft), Number(refAreaRight)];

      if (leftNum > rightNum) {
        [leftNum, rightNum] = [rightNum, leftNum];
      }

      setPendingZoom({
        start: new Date(leftNum),
        end: new Date(rightNum),
      });
      setSelectionArea({
        left: leftNum.toString(),
        right: rightNum.toString(),
      });

      setRefAreaLeft(null);
      setRefAreaRight(null);

      openContextMenu(event);
    },
    [refAreaLeft, refAreaRight, openContextMenu]
  );

  const mouseDownHandler = useCallback(
    (e: CategoricalChartState) => {
      if (!e?.activeLabel) {
        return;
      }
      if (refAreaLeft) {
        setRefAreaRight(e.activeLabel);
      } else {
        setRefAreaLeft(e.activeLabel);
      }
    },
    [refAreaLeft]
  );

  const mouseMoveHandler = useCallback(
    (e: CategoricalChartState) => {
      if (refAreaLeft && e?.activeLabel) {
        setRefAreaRight(e.activeLabel);
      }
    },
    [refAreaLeft]
  );

  const mouseUpHandler = useCallback(
    (
      nextState: CategoricalChartState,
      event: MouseEvent | React.MouseEvent
    ) => {
      const areaBounds = document
        .querySelector('.recharts-area')
        ?.getBoundingClientRect();

      if (!areaBounds) return;

      const isOutside = !Array.from(
        document.querySelectorAll('.recharts-area')
      ).some((el) => {
        const areaBounds = el.getBoundingClientRect();
        return (
          event.clientX >= areaBounds.left &&
          event.clientX <= areaBounds.right &&
          event.clientY >= areaBounds.top &&
          event.clientY <= areaBounds.bottom
        );
      });

      if (refAreaLeft && refAreaRight && !isOutside) {
        zoom(event);
      }
    },
    [refAreaLeft, refAreaRight, zoom]
  );

  const { i18n } = useTranslation();
  const locale = i18n.language === Languages.EN ? enLocale : deLocale;

  const getFormattedDateTime = (date: Date) => {
    const localDate = new Date(date);
    const seconds = localDate.getSeconds();
    const isEnglish = i18n.language === Languages.EN;

    let timeFormat = '';

    if (isEnglish) {
      timeFormat = seconds > 0 ? 'hh:mm:ss aaa' : 'hh:mm aaa';
    } else {
      timeFormat = seconds > 0 ? 'HH:mm:ss' : 'HH:mm';
    }

    return format(localDate, `iii, d LLL yyyy, ${timeFormat}`, { locale });
  };

  const getZoomRangeLabel = () => {
    if (!pendingZoom.start || !pendingZoom.end) return '';

    const startLabel = getFormattedDateTime(pendingZoom.start);
    const endLabel = getFormattedDateTime(pendingZoom.end);

    return `${startLabel} - ${endLabel}`;
  };

  return {
    contextMenuRef,
    refAreaLeft,
    refAreaRight,
    selectionArea,
    handleZoomConfirm,
    handleZoomAbort,
    handleContextMenuClose,
    mouseDownHandler,
    mouseMoveHandler,
    mouseUpHandler,
    getZoomRangeLabel,
  };
};
