import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  ReferenceArea,
} from 'recharts';

import Box from '@mui/material/Box';

import { CustomXAxisTick } from 'components/CustomXAxisTick';
import { ZoomContextMenu } from 'components/CustomContextMenu/ZoomContextMenu';
import { useChartZoom } from 'hooks/Chart/useChartZoom';

import { useSensorChartData } from 'hooks/ChartData/useSensorChartData';
import { useSensorYAxisRange } from 'hooks/ChartData/useSensorYAxisRange';
import { useChartCacheData } from 'hooks/ChartData/useChartCacheData';

import { getIsIntervalMoreTwoWeeks } from 'utils/helpers';

import { CustomYAxisRange, SensorData } from 'types/sensor';

import { theme } from 'styles/theme';

import { differenceInDays } from 'date-fns';
import { SensorChartSettings } from './SensorChartSettings';
import { ChartTooltip } from '../ChartTooltip';
import { ChartCursor } from '../ChartCursor';

const strokeColors = [theme.lightBlue, theme.darkBlue, theme.blue];

const getPrecision = (value: number) => {
  switch (true) {
    case value === 0:
      return 1;
    case value < 10 && value > -10:
      return 3;
    case (value >= 10 && value < 100) || (value <= -10 && value > -100):
      return 4;
    default:
      return 5;
  }
};

const getTicks = (min: number, max: number) => {
  const quarterDiff = (max - min) / 4;

  return [
    min,
    min + quarterDiff,
    min + 2 * quarterDiff,
    min + 3 * quarterDiff,
    max,
  ];
};

// From the Recharts documentation: tick?:((props: any) => ReactElement<SVGElement>)
// eslint-disable-next-line
const CustomYAxisTick: FC<any> = (props) => {
  const { x, y, payload, fill } = props;

  if (!payload) return null;

  return (
    <text x={x} y={y} textAnchor="middle" dx="-2.4em" fill={fill}>
      <tspan x={x}>
        {payload.value.toPrecision(getPrecision(+payload.value))}
      </tspan>
    </text>
  );
};

const getDomainMinMax = (
  min: number,
  max: number
): [domainMin: number, domainMax: number] => {
  if (min === Infinity || max === -Infinity) {
    return [min === Infinity ? 0 : min, max === -Infinity ? 100 : max];
  }

  if (min !== max) {
    const minCorrected = min < 0 ? min + min * 0.02 : min - min * 0.02;
    const maxCorrected = max < 0 ? max - max * 0.0025 : max + max * 0.0025;

    return [minCorrected, maxCorrected];
  }
  if (min === max && min !== 0) return [min - min / 2, max + max / 2];
  return [0, 0.1];
};

interface SensorChartProps {
  id: string;
  title: string;
  unit: string;
  data: SensorData[];
  isPending: boolean;
}

export const SensorChart: FC<SensorChartProps> = ({
  id,
  title,
  unit,
  data,
  isPending,
}) => {
  const { t } = useTranslation();
  const {
    customEndDate,
    customStartDate,
    dateRange,
    setCustomStartDateRange,
    setCustomEndDateRange,
  } = useChartCacheData();
  const { yAxisRange, onChangeYAxisRange } = useSensorYAxisRange(id, title);
  const [customYAxisRange, setCustomYAxisRange] =
    useState<CustomYAxisRange | null>(yAxisRange);
  const onChangeCustomYAxisRange = useCallback(
    (value: CustomYAxisRange | null) => {
      setCustomYAxisRange(value);
      onChangeYAxisRange(value);
    },
    [onChangeYAxisRange]
  );

  const {
    contextMenuRef,
    refAreaLeft,
    refAreaRight,
    selectionArea,
    handleZoomConfirm,
    handleZoomAbort,
    handleContextMenuClose,
    mouseDownHandler,
    mouseMoveHandler,
    mouseUpHandler,
    getZoomRangeLabel,
  } = useChartZoom({
    setCustomStartDateRange,
    setCustomEndDateRange,
  });

  const isLongDateFormat =
    !dateRange && getIsIntervalMoreTwoWeeks(customStartDate, customEndDate);

  const isRangeDateMoreThanOneDay = useCallback(() => {
    if (customEndDate && customStartDate) {
      return differenceInDays(customEndDate, customStartDate) > 1;
    }
    return false;
  }, [customEndDate, customStartDate]);

  const renderCustomTooltip = useCallback(
    ({ payload: payloads }: TooltipProps<string, string>) => {
      if (!payloads?.length) {
        return null;
      }

      const { date, showTooltip } = payloads[0].payload;
      return showTooltip ? (
        <ChartTooltip
          date={date}
          payloads={payloads}
          valueFormatter={(value) =>
            value === 'NaN' ? t('emptyMessage.default') : value || '0'
          }
          isRangeMoreOneDay={isRangeDateMoreThanOneDay()}
        />
      ) : null;
    },
    [isRangeDateMoreThanOneDay, t]
  );

  const maxChartValue = Math.max(...data.map((item) => item.value));
  const minChartValue = Math.min(...data.map((item) => item.value));
  const customDomainMinMax = [
    typeof customYAxisRange?.min === 'number'
      ? customYAxisRange.min
      : minChartValue,
    typeof customYAxisRange?.max === 'number'
      ? customYAxisRange.max
      : maxChartValue,
  ];
  const [domainMin, domainMax] = customYAxisRange
    ? customDomainMinMax
    : getDomainMinMax(minChartValue, maxChartValue);
  const zeroChart = minChartValue === maxChartValue && minChartValue === 0;

  const sensorChartData = useSensorChartData(
    domainMax,
    domainMin,
    dateRange,
    title,
    data
  );

  return (
    <Box
      sx={{
        border: '1px solid',
        width: '100%',
        height: 'fit-content',
        borderRadius: '4px',
        marginBottom: '10px',
        position: 'relative',
        borderColor: (theme) => theme.palette.custom.borderColor,
        backgroundColor: (theme) => theme.palette.custom.white,
      }}
    >
      <Box
        sx={{
          padding: '6px 16px',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '157%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '34px',
          borderColor: (theme) => theme.palette.custom.borderColor,
        }}
      >
        {isPending ? t('charts.loading') : `${title} ${unit}`}

        <SensorChartSettings
          value={customYAxisRange}
          setValue={onChangeCustomYAxisRange}
          initialValue={{ min: domainMin, max: domainMax }}
        />
      </Box>
      <ResponsiveContainer width="100%" height={260}>
        <ComposedChart
          syncId="syncId"
          data={sensorChartData}
          margin={{
            top: 30,
            right: 40,
            left: 30,
            bottom: 30,
          }}
          onMouseDown={mouseDownHandler}
          onMouseMove={mouseMoveHandler}
          onMouseUp={mouseUpHandler}
        >
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            interval="preserveStart"
            tick={<CustomXAxisTick isLongDateFormat={isLongDateFormat} />}
            type="number"
            scale="time"
            domain={['auto', 'auto']}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            allowDataOverflow
            tick={<CustomYAxisTick />}
            domain={[domainMin, domainMax]}
            ticks={getTicks(domainMin, domainMax)}
          />
          <CartesianGrid opacity={0.7} />
          {((refAreaLeft && refAreaRight) ||
            (selectionArea.left && selectionArea.right)) && (
            <ReferenceArea
              x1={refAreaLeft || selectionArea.left || 0}
              x2={refAreaRight || selectionArea.right || 0}
              strokeOpacity={0.3}
              fill="#8884d8"
              fillOpacity={0.3}
            />
          )}
          <Line
            type="linear"
            dataKey="value"
            stroke={strokeColors[0]}
            fillOpacity={0}
            strokeWidth={zeroChart ? 4 : 2}
            activeDot={false}
            dot={false}
            key={title}
            isAnimationActive={false}
          />
          <Tooltip
            content={renderCustomTooltip}
            isAnimationActive={false}
            cursor={<ChartCursor />}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <ZoomContextMenu
        ref={contextMenuRef}
        onClose={handleContextMenuClose}
        onConfirmZoom={handleZoomConfirm}
        onAbortZoom={handleZoomAbort}
        zoomRangeLabel={getZoomRangeLabel()}
        refAreaLeft={refAreaLeft}
        refAreaRight={refAreaRight}
      />
    </Box>
  );
};
